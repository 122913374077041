import React from 'react';
import { graphql } from 'gatsby';

import SVG from 'common/Icon/SVG';

import './IconList.scss';

const IconList = ({ iconList }: PageContent.IconListType) => (
  <div className="icons" data-testid="icon-list">
    {iconList.map(({ iconName, iconTitle, iconDescription }, index) => {
      const Icon = SVG[iconName];

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="icons__item" key={index}>
          <Icon className={iconName} />
          <p className="icons__title">{iconTitle}</p>
          {iconDescription ? <p className="icons__description">{iconDescription}</p> : null}
        </div>
      );
    })}
  </div>
);

export const IconListType = graphql`
  fragment IconListType on IconListType {
    iconName
    iconTitle
    iconDescription
  }
`;

export default IconList;
