import React from 'react';
import { graphql } from 'gatsby';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import IconList from 'common/IconList';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import FeatureBanner from 'components/FeatureBanner';
import ProductsListing from 'components/ProductsListing';
import SlimBanner from 'components/SlimBanner';
import { sortProductsByNodePicker } from 'utils/productsSorting';

import './ProductLanding.scss';

const ProductLanding = ({
  data: {
    productLanding: {
      urls,
      seo,
      banner,
      iconList,
      featureBanner,
      slimInfoBanner,
      customButton,
      productsListingTitle,
      productsFoundLabel,
      heroBanner,
      productsFilters,
      productsOrder,
    },
    allProductDetails: { nodes: products },
  },
  pageContext: {
    breadcrumb: { crumbs },
    lang,
  },
}: ProductLandingTypes.ProductLandingProps) => {
  const { title, keywords, description } = seo;
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  return (
    <Layout {...{ crumbsSettings }} headerTransparent className="product-landing">
      <Seo {...{ lang, urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...banner} />
      <FeatureBanner {...featureBanner} />
      <ProductsListing
        {...{
          products: sortProductsByNodePicker(productsOrder, products) as PageContent.ProductType[],
          customButton,
          productsListingTitle,
          productsFoundLabel,
          productsFilters,
        }}
      />
      <Banner {...heroBanner} />
      <section className="background-pink">
        <SlimBanner {...slimInfoBanner} />
        <Container>
          <IconList {...{ iconList }} />
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    productLanding(lang: { eq: $lang }) {
      productsOrder {
        name
      }
      productsListingTitle
      productsFoundLabel
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      banner {
        ...BannerType
      }
      heroBanner {
        ...BannerType
      }
      iconList {
        ...IconListType
      }
      featureBanner {
        ...FeatureBannerType
      }
      slimInfoBanner {
        ...SlimBannerType
      }
      customButton {
        ...CustomButtonType
      }
      productsFilters {
        ...ProductsFiltersType
      }
    }
    allProductDetails {
      ...ProductDetailsType
    }
  }
`;

export default ProductLanding;
