import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { graphql } from 'gatsby';
import { useScreenRecognition } from 'hooks/useScreenRecognition';

import Container from 'layout/Container';
import CustomButton from 'common/CustomButton';
import FormControlSelect from 'components/Form/common/FormControlSelect';
import FormControlOption from 'components/Form/common/FormControlSelect/FormControlOption';
import Listing from 'components/Listing';
import {
  FILTER_ACTIVE_CONTROL,
  FILTER_VALUE_ALL,
  INIT_PRODUCTS_FILTERS,
  PRODUCTS_FILTERS,
  SORT_PRODUCTS_BY,
  SORT_PRODUCTS_BY_OPTIONS,
} from 'utils/constants';
import getProductsListingModel from 'utils/getProductsListingModel';
import { sortAsc, sortByBestselling, sortByNewest } from 'utils/productsSorting';

import ProductsControls from './ProductsControls';
import ProductsFilters from './ProductsFilters';

import './ProductsListing.scss';

const ProductsListing = ({
  sortByPlaceholder,
  sortByLabel,
  products,
  customButton,
  productsListingTitle,
  productsFoundLabel,
  productsFilters,
  isProductCategory = false,
}: PageContent.ProductsListingType) => {
  const [isFilterActive, setIsFilterActive] = useState(true);
  const [isClearFilterActive, setIsClearFilterActive] = useState(false);
  const [filters, setFilters] = useState(INIT_PRODUCTS_FILTERS);
  const [sortBy, setSortBy] = useState('');
  const [areFiltersVisible, setAreFiltersVisible] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const { register, getValues, setValue } = useForm({
    mode: 'onChange',
  });
  const { isMiddleMobile } = useScreenRecognition();

  const label = productsFoundLabel || 'products found';
  const numberOfProducts = `${filteredProducts.length} ${label}`;
  const {
    filtersTitleMobile,
    filterBy,
    applyFilter,
    clearFilter,
    filterCategories,
    keywordsLabel,
    keywordsPlaceholder,
  } = productsFilters;

  const handleValidation = (_regexPattern, fieldID: string): boolean => {
    const fieldValue = getValues(fieldID);

    setFilters({ ...filters, [fieldID]: fieldValue });
    setIsFilterActive(true);
    setIsClearFilterActive(false);

    return true;
  };

  const filterByCategories = ({
    filterByFormat,
    filterByMessType,
    filterByProductRange,
  }): boolean =>
    (filterByFormat?.includes(filters[PRODUCTS_FILTERS.format]) ||
      (filterByFormat?.length && filters[PRODUCTS_FILTERS.format] === FILTER_VALUE_ALL)) &&
    (filterByMessType?.includes(filters[PRODUCTS_FILTERS.mess]) ||
      (filterByMessType?.length && filters[PRODUCTS_FILTERS.mess] === FILTER_VALUE_ALL)) &&
    (filterByProductRange?.includes(filters[PRODUCTS_FILTERS.range]) ||
      (filterByProductRange?.length && filters[PRODUCTS_FILTERS.range] === FILTER_VALUE_ALL));

  const filterByKeywords = ({
    keywords,
  }: {
    keywords: PageContent.FilterByKeywordsType[];
  }): boolean =>
    keywords
      ?.map(({ value }) => value.includes(filters[PRODUCTS_FILTERS.keywords]))
      .includes(true) || !filters[PRODUCTS_FILTERS.keywords];

  const filterProducts = (productsToFilter): PageContent.ProductType[] =>
    productsToFilter.filter(
      ({ filterByFormat, filterByMessType, filterByProductRange, filterByKeywords: keywords }) =>
        filterByKeywords({ keywords }) &&
        filterByCategories({ filterByFormat, filterByMessType, filterByProductRange })
    );

  const clearFilters = (): void => {
    filterCategories.forEach(({ title, values }) => {
      setValue(title, values[0].value);
    });
    setValue(PRODUCTS_FILTERS.keywords, '');
    setFilters(INIT_PRODUCTS_FILTERS);
    setFilteredProducts(products);
  };

  const showMobileFilters = (): void => {
    setAreFiltersVisible(true);
    document.body.classList.add('hidden');
    window.scrollTo(0, 0);
  };

  const closeMobileFilters = (): void => {
    setAreFiltersVisible(false);
    document.body.classList.remove('hidden');
  };

  const handleClick = (filterState: string): void => {
    setIsClearFilterActive(filterState === FILTER_ACTIVE_CONTROL.clear);
    setIsFilterActive(!(filterState === FILTER_ACTIVE_CONTROL.clear));

    if (filterState === FILTER_ACTIVE_CONTROL.clear) {
      clearFilters();
    } else {
      setFilteredProducts(filterProducts(products));

      if (isMiddleMobile) {
        closeMobileFilters();
      }
    }
  };

  const handleProductsSort = useCallback(
    (sortingType) => {
      setSortBy(sortingType);

      setFilteredProducts([
        ...filteredProducts.sort(
          {
            [SORT_PRODUCTS_BY.bestselling]: sortByBestselling,
            [SORT_PRODUCTS_BY.newest]: sortByNewest,
            [SORT_PRODUCTS_BY.asc]: sortAsc,
          }[sortingType]
        ),
      ]);
    },
    [filteredProducts]
  );

  useEffect(() => {
    clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFilterActive) {
      setFilteredProducts(
        filterProducts(products).sort(
          {
            [SORT_PRODUCTS_BY.bestselling]: sortByBestselling,
            [SORT_PRODUCTS_BY.newest]: sortByNewest,
            [SORT_PRODUCTS_BY.asc]: sortAsc,
          }[sortBy]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortBy]);

  useEffect(() => {
    setAreFiltersVisible(!isMiddleMobile);
    setIsFilterActive(!isMiddleMobile);
  }, [isMiddleMobile]);

  return (
    <Container>
      <section className="products-listing" id="main-content">
        {productsListingTitle ? (
          <h2 className="products-listing__title">{productsListingTitle}</h2>
        ) : null}
        <div className="products-listing__wrapper">
          {areFiltersVisible ? (
            <ProductsFilters
              {...{
                keywordsLabel,
                filtersTitleMobile,
                keywordsPlaceholder,
                isMiddleMobile,
                filterBy,
                applyFilter,
                isFilterActive,
                handleClick,
                clearFilter,
                isClearFilterActive,
                filterCategories: filterCategories.slice(isProductCategory ? 1 : 0),
                register,
                setValue,
                getValues,
                handleValidation,
                closeMobileFilters,
              }}
            />
          ) : (
            <CustomButton
              ariaLabel={filterBy}
              buttonLabel={filterBy}
              backgroundColor="red"
              className="filters-controls__button-m"
              onClick={showMobileFilters}
            />
          )}
          {areFiltersVisible && isMiddleMobile ? (
            <ProductsControls
              {...{
                filterBy,
                applyFilter,
                isFilterActive,
                handleClick,
                clearFilter,
                isClearFilterActive,
                isMiddleMobile,
              }}
            />
          ) : null}
          <div className="products-listing__products">
            <div className="products-listing__header">
              <p className="eyebrow">{numberOfProducts}</p>
              <FormControlSelect
                {...{
                  type: 'select',
                  label: sortByPlaceholder || SORT_PRODUCTS_BY.label,
                  placeholder: sortByLabel || SORT_PRODUCTS_BY.placeholder,
                  onChange: handleProductsSort,
                  isOutsideForm: true,
                }}
              >
                {SORT_PRODUCTS_BY_OPTIONS.map((option) => (
                  <FormControlOption key={option} value={option}>
                    {option}
                  </FormControlOption>
                ))}
              </FormControlSelect>
            </div>
            <Listing
              items={getProductsListingModel(filteredProducts)}
              customButton={customButton}
              itemsPerPage={12}
            />
          </div>
        </div>
      </section>
    </Container>
  );
};

export const ProductsFiltersType = graphql`
  fragment ProductsFiltersType on ProductsFiltersType {
    filtersTitleMobile
    keywordsPlaceholder
    keywordsLabel
    filterBy
    applyFilter
    clearFilter
    filterCategories {
      title
      values {
        value
      }
    }
  }
`;

export default ProductsListing;
