import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import CustomButton from 'common/CustomButton';

import ListingItems from './ListingItems';

import './Listing.scss';

const Listing: FC<PageContent.ListingProps> = ({
  items,
  className,
  customButton,
  itemsPerPage,
  itemsPerLoad = 3,
}) => {
  const [itemsToShow, setItemsToShow] = useState<PageContent.ListingItem[]>([]);
  const [next, setNext] = useState(itemsPerPage);
  const { ariaLabel, buttonLabel, backgroundColor } = customButton || {};

  useEffect(() => {
    setItemsToShow(items.slice(0, itemsPerPage));
  }, [items, itemsPerPage]);

  const handleShowMorePosts = () => {
    setNext(next + itemsPerLoad);
    setItemsToShow(items.slice(0, next + itemsPerLoad));
  };

  return (
    <>
      <div className={classnames('listing', className)}>
        <ListingItems items={itemsToShow} />
      </div>
      {itemsToShow.length !== items.length && customButton ? (
        <div className="listing__show-more">
          <CustomButton
            buttonLabel={buttonLabel!}
            ariaLabel={ariaLabel!}
            backgroundColor={backgroundColor!}
            onClick={handleShowMorePosts}
          />
        </div>
      ) : null}
    </>
  );
};

export const ListingType = graphql`
  fragment ListingType on ListingType {
    items {
      title
      description
      link {
        ...LinkType
      }
      image {
        ...UmbracoImage
      }
    }
    customButton {
      ...CustomButtonType
    }
    itemsPerPage
  }
`;

export default Listing;
