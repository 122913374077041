import { nanoid } from 'nanoid';

const getProductsListingModel = (products: PageContent.ProductType[]): PageContent.ListingItem[] =>
  products.map(({ title, descriptionShort, url, productImage: { salsifyImage, altText } }) => ({
    id: nanoid(),
    title,
    description: descriptionShort!,
    link: {
      url,
      name: title,
    },
    image: {
      ...salsifyImage,
      altText,
    },
  }));

export default getProductsListingModel;
