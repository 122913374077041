import React from 'react';

import FormControlInput from 'components/Form/common/FormControlInput';
import FormGroupControl from 'components/Form/FormGroupControl';

import ProductsControls from '../ProductsControls';

const ProductsFilters = ({
  filtersTitleMobile,
  keywordsLabel,
  keywordsPlaceholder,
  isMiddleMobile,
  filterBy,
  isFilterActive,
  handleClick,
  isClearFilterActive,
  clearFilter,
  filterCategories,
  register,
  setValue,
  getValues,
  handleValidation,
  applyFilter,
  closeMobileFilters,
}: PageContent.ProductsFiltersComponentType) => (
  <div className="products-listing__filters">
    <div className="products-listing__filters-controls">
      {isMiddleMobile ? (
        <div className="products-filters-title">
          <h2 className="products-filters-title__title">{filtersTitleMobile}</h2>
          <span
            role="button"
            className="products-filters-title__close"
            onClick={closeMobileFilters}
            onKeyDown={closeMobileFilters}
            tabIndex={0}
            aria-label={filtersTitleMobile}
          />
        </div>
      ) : (
        <ProductsControls
          {...{
            filterBy,
            applyFilter,
            isFilterActive,
            handleClick,
            clearFilter,
            isClearFilterActive,
            isMiddleMobile,
          }}
        />
      )}
      <FormControlInput
        {...{
          type: 'text',
          label: keywordsLabel,
          placeholder: keywordsPlaceholder,
          fieldID: keywordsLabel,
          register,
          setValue,
          getValues,
          handleValidation,
        }}
      />
    </div>
    {filterCategories.map(({ title, values }) => (
      <FormGroupControl
        key={title}
        {...{
          fieldID: title,
          radioGroupLabel: title,
          radioCheckBoxes: values.map(({ value }) => ({
            label: value,
            type: 'radio',
          })),
          register,
          setValue,
          getValues,
          handleValidation,
        }}
      />
    ))}
  </div>
);

export default ProductsFilters;
