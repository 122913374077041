import React from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import CustomLink from 'common/CustomLink';
import Image from 'common/Image';

import './FeatureBanner.scss';

const FeatureBanner = ({ title, featureItems }: PageContent.FeatureBannerType) => (
  <div className="feature__wrapper" data-testid="feature-banner">
    {title ? <DangerouslySetInnerHtml html={title} className="feature__title" /> : null}
    <div className="feature__items">
      {featureItems?.map(({ image, description, customLink }) => {
        const { linkLabel, color, link, isLinkExternal } = customLink || {};

        return (
          <div className="feature__item" key={description}>
            {image ? (
              <Image imageData={image} alt={image.altText} />
            ) : (
              <CustomLink
                linkLabel={linkLabel!}
                color={color!}
                link={link!}
                isLinkExternal={isLinkExternal!}
              />
            )}
            <DangerouslySetInnerHtml
              html={description}
              className={classNames('feature__description', {
                [`feature__description--link`]: !image,
              })}
            />
          </div>
        );
      })}
    </div>
  </div>
);
export default FeatureBanner;
