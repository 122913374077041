import { SORT_PRODUCTS_BY } from './constants';

const getProductBestsellerValue = (product: PageContent.ProductType) =>
  product.tags.find((tag) => tag.name === SORT_PRODUCTS_BY.bestselling)!.value;

export const sortByBestselling = (a: PageContent.ProductType, b: PageContent.ProductType) =>
  getProductBestsellerValue(b) - getProductBestsellerValue(a);

export const sortAsc = (a: PageContent.ProductType, b: PageContent.ProductType) =>
  a.title?.localeCompare(b.title);

export const sortByNewest = (a: PageContent.ProductType, b: PageContent.ProductType) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

export const sortProductsByNodePicker = (
  productsOrder: PageContent.ProductsOrderType[],
  products: PageContent.ProductType[]
) =>
  productsOrder
    .map(({ name }) => products.find(({ title }) => title === name))
    .filter((product) => product);

export const sortRecommendedProducts = (productsOrder, products) =>
  productsOrder
    .map(({ value }) => products.find(({ sku }) => sku === value))
    .filter((product) => product);
