import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { DangerouslySetInnerHtml } from '../../layout';
import { ListingItemsProps } from './models';

const ListingItems: FC<ListingItemsProps> = ({ items }) => (
  <>
    {items.map(({ title, image, link, description, id }) => (
      <div key={id} className="listing__content" data-testid="listing-item">
        <div className="listing__image">
          <Image alt={image.altText} imageData={image} />
        </div>
        <div className="listing__title">
          <h4>{link ? <Link to={link.url}>{link.name}</Link> : title}</h4>
        </div>
        <div className="listing__description">
          <DangerouslySetInnerHtml html={description} element="p" />
        </div>
      </div>
    ))}
  </>
);

export default ListingItems;
