import React from 'react';
import classNames from 'classnames';

import CustomButton from 'common/CustomButton';
import { FILTER_ACTIVE_CONTROL } from 'utils/constants';

const ProductsControls = ({
  filterBy,
  applyFilter,
  isFilterActive,
  handleClick,
  clearFilter,
  isClearFilterActive,
  isMiddleMobile,
}: PageContent.ProductsControlsType) => (
  <div className="filters-controls">
    <CustomButton
      ariaLabel={isMiddleMobile ? applyFilter : filterBy}
      backgroundColor="red"
      buttonLabel={isMiddleMobile ? applyFilter : filterBy}
      className={classNames('filters-controls__button filters-controls__button--filter', {
        'filters-controls__button--active': isFilterActive,
        'filters-controls__button--filter-m': isMiddleMobile,
      })}
      disabled={isFilterActive && !isMiddleMobile}
      onClick={() => handleClick(FILTER_ACTIVE_CONTROL.filter)}
    />
    {!isMiddleMobile ? <span className="filters-controls__divider" /> : null}
    <CustomButton
      ariaLabel={clearFilter}
      backgroundColor="white"
      buttonLabel={clearFilter}
      className={classNames('filters-controls__button filters-controls__button--clear', {
        'filters-controls__button--active': isClearFilterActive,
        'filters-controls__button--clear-m': isMiddleMobile,
      })}
      disabled={isClearFilterActive && !isMiddleMobile}
      onClick={() => handleClick(FILTER_ACTIVE_CONTROL.clear)}
    />
  </div>
);

export default ProductsControls;
